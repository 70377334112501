import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';
import { MY_ACCOUNT_MENUS } from 'src/data/header';
import styles from './desktop.module.scss';

const MyAccount = () => {
  const { logout } = useAuth0();
  return (
    <div className={styles.userProfile}>
      <div className={styles.userIcon}>
        <FontAwesomeIcon icon={faUserCircle} />
      </div>
      <ul className={styles.desktopSubMenu}>
        {MY_ACCOUNT_MENUS.SUB_MENUS.map((row, index) => (
          <li key={`headerSubMenu${index}`}>
            {row.isLogout ? (
              <Button
                handleClick={() => logout({ returnTo: window.location.origin })}
              >
                Logout
              </Button>
            ) : (
              <Link activeClassName="active" to={row.link}>
                {row.subMenu}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MyAccount;
