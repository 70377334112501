import React, { useState } from 'react';
import _ from 'lodash';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { Collapse as RCollapse, Card } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';
import { MY_ACCOUNT_MENUS } from 'src/data/header';
import styles from '../mobile/Collapse/collapse.module.scss';

const MyAccount = () => {
  const location = useLocation();
  const { logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const isActive = !!_.find(MY_ACCOUNT_MENUS.SUB_MENUS, {
    link: location.pathname,
  });

  return (
    <>
      <Button handleClick={toggle} type={isActive ? styles.active : ''}>
        {MY_ACCOUNT_MENUS.MENU}
        <span>{isOpen ? '-' : '+'}</span>
      </Button>
      <RCollapse isOpen={isOpen}>
        <Card className={styles.card}>
          <ul className={`m-0 ${styles.myAccountWrapper}`}>
            {MY_ACCOUNT_MENUS.SUB_MENUS.map((row, index) => (
              <li key={`myAccountSubMenu${index}`}>
                {row.isLogout ? (
                  <Button
                    handleClick={() =>
                      logout({ returnTo: window.location.origin })
                    }
                  >
                    Logout
                  </Button>
                ) : (
                  <Link activeClassName={styles.active} to={row.link}>
                    {row.subMenu}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </Card>
      </RCollapse>
    </>
  );
};

export default MyAccount;
