import React from 'react';
import { APP_STORE_LINKS } from './data';

const Links = () => (
  <div className="store-button">
    {APP_STORE_LINKS.map((menu, index) => (
      <span key={index}>
        <a href={menu.path} target="_blank" rel="noreferrer">
          <img src={menu.icon} alt={menu.title} />
        </a>
      </span>
    ))}
  </div>
);

export default Links;
