import React from 'react';
import _ from 'lodash';
import { Link } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';
import NavItems from '../NavItems';
import MyAccount from '../MyAccount';
import CloseIcon from 'svg/CloseIcon';
import GoodingLogo from 'static/svg/GoodingLogo';
import { BLUE } from 'utils/colorsConstants'
import styles from './sideMenu.module.scss';

const SideMenu = ({ closeSideMenu, isOpen }) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <div
      className={`${styles.sideMenuWrapper} ${
        isOpen ? styles.openSideMenuWrapper : ''
      }`}
    >
      <div className={styles.menuHeader}>
        <Link to="/">
          <GoodingLogo color={BLUE} />
        </Link>

        <Button
          handleClick={() => _.isFunction(closeSideMenu) && closeSideMenu()}
        >
          <CloseIcon color="silver" />
        </Button>
      </div>
      <ul>
        <li>
          {isAuthenticated ? (
            <MyAccount />
          ) : (
            <Button
              handleClick={() => {
                localStorage.setItem(
                  'currentRoute',
                  `${window.location.pathname}${window.location.search}`
                );
                loginWithRedirect();
              }}
            >
              LOGIN / SIGN UP
            </Button>
          )}
        </li>
        <NavItems />
      </ul>
    </div>
  );
};

export default SideMenu;
