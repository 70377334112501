import React from 'react';
// import * as Yup from 'yup';
import { Link } from 'gatsby';
// import Button from 'components/Button';
// import TextInput from 'components/form-elements/TextInput';
// import { Formik, Form } from 'formik';
import Menus from './Menus';
import SocialMenus from './SocialMenus';
import AppStoreLinks from './AppStoreLinks';
import ContactInfo from './ContactInfo';
import { useStaticQueryGetNavItems } from 'shared/queries/navigations';
import { useAuth0 } from '@auth0/auth0-react';
import './footer.scss';
import { PRIVACY_POLICY_PATH } from 'src/utils/pathsConstants';

// const signUpInitialValues = { email: '' };
// const SignUpSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Please enter a valid email address.')
//     .required('Required'),
// });

const AppFooter = ({ link }) => {
  const { isAuthenticated } = useAuth0();
  const { footerNavItems } = useStaticQueryGetNavItems();

  const renderNavigationItems = footerNavItems => {
    return (
      footerNavItems?.length &&
      footerNavItems.map(({ title, subElements, id, viewMode, slug }) => {
        const showMenu =
          (isAuthenticated && viewMode.includes('Logged In')) ||
          viewMode.includes('Visitor');
        if (showMenu) {
          return renderItemsAfterCheck(subElements, id, title, slug);
        } else {
          return null;
        }
      })
    );
  };

  const renderItemsAfterCheck = (subElements, id, title, slug) => {
    return subElements ? (
      <div key={id}>
        <h5 className="letterSpace">{title}</h5>
        <ul>
          <Menus key={id} list={subElements} />
        </ul>
      </div>
    ) : (
      <div key={id}>
        <a href={slug ? slug : '/'}>
          <h5>{title}</h5>
        </a>
      </div>
    );
  };

  return (
    <div className="footer">
      <div className="mainWrapper">
        {/* <div className="footer-signUp">
          <h3>SIGN UP FOR UPDATES</h3>
          <Formik
            initialValues={signUpInitialValues}
            validationSchema={SignUpSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <TextInput
                  name="email"
                  placeholder="Enter Email Address"
                  floating={false}
                />
                <Button
                  type="app-transparent-white-button"
                  disabled={!!errors.email || !touched.email}
                  handleClick={() => {
                    if (link) {
                      window.location.href = link;
                    }
                  }}
                >
                  Sign Up
                </Button>
              </Form>
            )}
          </Formik>
        </div> */}

        {/* Fake div for keep the current design till signup back */}
        <div className="signupFakeWidth"></div>

        <div className="footer-li flex-wrap">
          {renderNavigationItems(footerNavItems)}
        </div>

        <div className="address">
          <ContactInfo />
          <SocialMenus />
          <AppStoreLinks />
        </div>
      </div>
      <div className="terms">
        <p>
          <Link to="/terms">TERMS & CONDITIONS</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
          <Link to={PRIVACY_POLICY_PATH}>PRIVACY POLICY</Link>
        </p>
        <p>
          ©{new Date().getFullYear()} by Gooding & Company Inc. All Rights
          Reserved.
        </p>
      </div>
    </div>
  );
};

export default AppFooter;
