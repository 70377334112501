const MY_ACCOUNT_MENUS = {
  MENU: 'MY ACCOUNT',
  SUB_MENUS: [
    {
      subMenu: 'Profile',
      link: '/auth/profile',
    },
    {
      subMenu: 'Following',
      link: '/auth/following',
    },
    {
      subMenu: 'Logout',
      isLogout: true,
    },
  ],
};

export { MY_ACCOUNT_MENUS };
