import React, { Fragment } from 'react';
import { CONTACT_INFO } from './data';
import CopyClipboard from 'components/CopyClipboard';
import PhoneNumber from 'components/PhoneNumber'
import GCO_Logo from 'static/svg/GCO_Logo.svg';

const { telephone, email, telWithCountryCode } = CONTACT_INFO;

const Address = () => (
  <Fragment>
    <div>
      <h2 className="brand-heading">
        <div
          className="footer-logo"
          style={{ backgroundImage: `url(${GCO_Logo})` }}
        ></div>
      </h2>
      <p>Auctions and Brokerage</p>
    </div>
    <div>
      <p>
<a href={`tel:${telWithCountryCode}`}>{<PhoneNumber number={telephone}></PhoneNumber>}</a>
      </p>
      <p className="footer-email-mobile">
        <a href={`mailto:${email}`}>{email}</a>
      </p>
      <div className="footer-email-desktop">
        <CopyClipboard id="footerEmail" text={email} />
      </div>
    </div>
  </Fragment>
);

export default Address;
