import React from 'react';
import { Link } from 'gatsby';
import { externalLinkPattern } from 'shared/helpers/patterns';
import { useAuth0 } from '@auth0/auth0-react';

const Menus = ({ list }) => {
  const { isAuthenticated } = useAuth0();

  const renderListItems = list => {
    return (
      list?.length &&
      list.map(({ title, slug, viewMode, id }) => {
        if (isAuthenticated) {
          if (viewMode.includes('Logged In')) {
            return renderItemsAfterCheck(title, id, slug);
          } else {
            return null;
          }
        } else {
          if (viewMode.includes('Visitor')) {
            return renderItemsAfterCheck(title, id, slug);
          } else {
            return null;
          }
        }
      })
    );
  };

  const renderItemsAfterCheck = (title, id, slug) => {
    const isLink = slug ? externalLinkPattern.test(slug) : false;
    return (
      <li key={id}>
        {isLink ? (
          <a
            href={slug}
            target="_blank"
            rel="noreferrer"
            className="letterSpace"
          >
            {title}
          </a>
        ) : (
          <Link to={slug} className="letterSpace">
            {title}
          </Link>
        )}
      </li>
    );
  };

  return <>{renderListItems(list)}</>;
};

export default Menus;
