import React, { Fragment } from 'react';
import DesktopHeader from './desktop';
import MobileHeader from './mobile';

export default ({ transparentHeader }) => (
  <Fragment>
    <DesktopHeader transparentHeader={transparentHeader} />
    <MobileHeader transparentHeader={transparentHeader} />
  </Fragment>
);
