import React, { Fragment } from 'react';
import AppHeader from 'components/Header';
import AppFooter from 'components/Footer';
import { nodePropType } from 'shared/propTypes';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import './layout.scss';

const Layout = ({
  children,
  transparentHeader,
  paddingTop,
  hideFooter,
  withOutBodyPadding,
}) => (
  <Fragment>
    <Helmet>
      <meta name="viewport" content="width=device-width, user-scalable=no" />
    </Helmet>
    <div className="app-container">
      <AppHeader transparentHeader={transparentHeader} />
      <div
        className={`app-body ${paddingTop ? 'app-body-top-padding' : ''} ${
          withOutBodyPadding ? 'pb-0' : ''
        }`}
      >
        {children}
      </div>
      {!hideFooter && (
        <div className="app-footer">
          <AppFooter />
        </div>
      )}
    </div>
  </Fragment>
);

Layout.propTypes = {
  children: nodePropType.isRequired,
  transparentHeader: PropTypes.bool,
  paddingTop: PropTypes.bool,
};

Layout.defaultProps = {
  transparentHeader: false,
  paddingTop: true,
};

export default Layout;
