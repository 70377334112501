import React from 'react';
import Link from 'components/Link/Link';
import { useAuth0 } from '@auth0/auth0-react';
import { useStaticQueryGetNavItems } from 'shared/queries/navigations';
import HeaderDropDown from './DropDown';
import styles from './desktop.module.scss';

export default () => {
  const { isAuthenticated } = useAuth0();
  const { headerNavItems } = useStaticQueryGetNavItems();

  const renderNavigationItems = headerNavItems =>
    headerNavItems?.length &&
    headerNavItems.map(({ title, subElements, id, viewMode, slug }, index) => {
      const menu =
        (isAuthenticated && viewMode.includes('Logged In')) ||
        viewMode.includes('Visitor');

      if (menu) {
        return renderItemsAfterCheck(subElements, id, title, slug, index);
      } else {
        return null;
      }
    });

  const renderItemsAfterCheck = (subElements, id, title, slug, index) =>
    subElements ? (
      <HeaderDropDown key={id} id={index} list={subElements} title={title} />
    ) : (
      <li key={id}>
        <Link activeClassName={styles.active} to={slug}>
          {title}
        </Link>
      </li>
    );

  return (
    <ul className={`${styles.navWrapper} mr-auto`}>
      {renderNavigationItems(headerNavItems)}
    </ul>
  );
};
